<template>
  <div class="flex box">
    <div class="m-left">
      <img :src="this.$OssAddress + 'bg-1.png'" alt="">
      <h4>学生信息</h4>
      <div v-if="stuDataCont">
        <p><span>学生姓名</span>{{ stuDataCont.student_info.student_name }}</p>
        <p><span>性别</span>{{ stuDataCont.student_info.student_gender }}</p>
        <p><span>年级</span>{{ stuDataCont.grade_info }}</p>
        <p><span>学号</span>{{ stuDataCont.student_info.student_no }}</p>
        <p><span>教学班</span>{{ stuDataCont.teaching_info.teaching_name }}</p>
        <p><span>行政班</span>{{ stuDataCont.class_info.class_name}}</p>
        <p><span>教学老师</span>{{ stuDataCont.teaching_info.teacher_name }}</p>
        <p><span>班主任</span>{{ stuDataCont.class_info.teacher_name }}</p>
      </div>
    </div>
    <div class="m-right">
      <div class="m-right-main">
				<h4>学习习惯检查</h4>
				<div style="display: flex;justify-content: space-between;align-items: center">
					<div class="menu">
						<span v-for="(item, index) in menuConfig" :class="active === index ? 'active' : ''" @click="menuClick(index,item.type)">{{ item.name }}</span>
					</div>
					<div v-if="dataCont" style="margin-right: 24rem;color: rgba(0,0,0,0.4);font-size: 16rem">
						共计{{ dataCont.length }}条
					</div>
				</div>
				<div style="padding-bottom: 80rem;overflow: scroll;height: calc(100% - 94rem)">
					<template v-if="dataCont && dataCont.length">
						<div v-for="item in dataCont" class="right-box">
							<div>
								<span>检查科目</span>
								<div>{{ item.subject_info }}</div>
							</div>
							<div>
								<span>检查时间</span>
								<div>{{ item.check_time }}</div>
							</div>
							<div>
								<span>检查老师</span>
								<div>{{ item.teacher_info }}</div>
							</div>
							<div>
								<span>检查等级</span>
								<div>{{ item.level_name }}</div>
							</div>
							<div>
								<span>检查情况</span>
								<div>{{ item.describe }}</div>
							</div>
							<div>
								<span>图片</span>
								<div>
									<el-image
											v-for="c_item in item.img"
											:preview-src-list="item.img"
											:src="c_item"
											style="width: 200rem; height: 200rem;margin-right: 20rem">
									</el-image>
								</div>
							</div>
						</div>
					</template>
					<t-result v-else style="padding-top: 160rem" type='empty'></t-result>
				</div>
				<div style="height: 80rem;background-color: #fff;position: absolute;bottom: 0;left: 0;width: 96%;padding-left: 20rem;border-top: 1rem solid #eee">
					<el-button style="margin-top: 15rem" @click="$router.back()">返回</el-button>
				</div>
			</div>
    </div>
  </div>
</template>

<script>
export default {
	_config:{"route":{"path":"details","meta":{"title":"详情"}}},
  data(){
    return{
      allData: '',
      dataCont: '',
      stuDataCont: '',
      menuConfig: [],
      active: 0
    }
  },
  created() {
    this.getData()
  },
  methods:{
    getData(){
      this.$_axios2('api/educational-routine/student-routine-detail?task_id=' + this.$route.query.id).then(res => {
        let data = res.data.data;
        this.menuConfig = data.knack_list;
        let type = data.knack_list[0].type
        this.allData = data.result || {};
        this.dataCont = data.result[type]
      })
      this.$_axios2('api/educational-routine/student-routine-detail-base?task_id=' + this.$route.query.id).then(res => {
        this.stuDataCont = res.data.data
      })
    },

    menuClick(index,type){
      this.active = index;
      this.dataCont = this.allData[type]
    }
  }
}
</script>

<style scoped lang="scss">
h4{
  font-size: 22rem;
  color: rgba(0,0,0,.8);
}
.box{
  height: calc(100vh - 100rem);
  overflow: scroll;
  background-color: #f7f7f7 !important;
  padding-bottom: 0 !important;
}
.m-left{
  background-color: #fff;
  height: 100%;
  width: 320rem;
  position: relative;
  overflow: hidden;
  padding: 30rem 0 0 30rem;
  h4{
    margin-bottom: 40rem;
  }
  img{
    width: 80%;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  p{
    color: rgba(0,0,0,.6);
    padding-left: 20rem;
    margin-top: 20rem;
  }
  span{
    display: inline-block;
    width: 100rem;
    color: rgba(0,0,0,.8);
  }
}
.m-right{
  background-color: #fff;
  height: 100%;
  flex: 1;
  margin-left: 20rem;
  overflow: scroll;
  .m-right-main{
    padding: 30rem 0 0 30rem;
    height: 100%;
    position: relative;
    overflow: hidden;
  }
}
.menu{
  display: inline-block;
  border-radius: 6rem;
  margin-top: 30rem;
  overflow: hidden;
  span{
    background-color: #f7f7f7;
    text-align: center;
    width: 120rem;
    display: inline-block;
    height: 32rem;
    line-height: 32rem;
    position: relative;
    transition: .25s;
    cursor: pointer;

    &:after{
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 1rem;
      height: 80%;
      background-color: rgba(231,231,231,0.5);
    }

    &.active{
      background-color: #1C4EFD;
      color: #fff;
    }
  }
}
.right-box{
  background: rgba(234,234,234,0.2);
  padding: 20rem 0 30rem 30rem;
  margin-top: 20rem;

  &>div{
    display: flex;
    margin-bottom: 20rem;
    color: rgba(0,0,0,.6);
    line-height: 1.8;

    span{
      display: inline-block;
      width: 100rem;
      min-width: 100rem;
      color: rgba(0,0,0,.8);
    }
  }
}
</style>
